<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张磊（zhanglei1204@jd.com）
 * @Date: 2021-05-16 20:39:14
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-17 01:22:42
 * @FilePath: /官网迭代/src/views/admin/teacher/foundation.vue
-->
<template>
  <div class="foundation_page">
    <div class="tabTit">
      <span class="text">体质测试</span>
      <div class="btn-add tab" @click="toTest" style="font-weight: bold;text-align:center; width: 1.5rem">
        进入测试
      </div>
    </div>
    <div class="custTab">
      <table class="layui-table" lay-skin="nob">
        <thead>
          <tr>
            <th>测试项目</th>
            <th>测试器材</th>
            <th>测试规则</th>
            <th>测试要求</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" :key="item.id">
            <td style="width: 100px">{{ item.title }}</td>
            <td style="width: 100px">{{ item.testingEq }}</td>
            <td style="width: 400px">{{ item.testingRule }}</td>
            <td style="width: 300px">{{ item.testingRq }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "foundation",
  components: {},
  data() {
    return {
      list: [],
    };
  },
  created() {},
  mounted() {
    this.getTZList();
  },
  methods: {
    getTZList() {
      this.api.admin.testingByType().then((res) => {
        this.list = res.data;
      });
    },
    toTest(){
      this.$router.push({
        path: "/testCheck",
        query: {
          gradeId: this.$route.query.id,
          isSports: false,
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.foundation_page {
  width: 100%;
  .tabTit {
    min-height: 0.9rem;
    box-sizing: border-box;
    /* text-align: right; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .tabTit .tips {
    font-size: 0.2rem;
    /* position: absolute;
    left: 0; */
  }

  .tabTit .text {
    line-height: 0.3rem;
    font-weight: 700;
    color: #cb1b31;
    font-size: 0.3rem;
    /* padding-right: .5rem;
    text-align: right;
    float: right; */
  }

  .tabTit .searchdiv {
    display: flex;
  }

  .tabTit .searchdiv .btn-search {
    margin-right: 0.2rem;
  }

  .tabTit .btn-add,
  .tabTit .btn-blue {
    padding: 0rem 0.4rem;
    height: 38px;
    line-height: 38px;
    font-size: 0.22rem;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    border: none;
  }

  .tabTit .btn-add {
    background: #cb1b31;
    color: #fff;
  }

  .tabTit .btn-blue {
    background: #228cec;
    color: #fff;
  }
  .custTab {
    /* padding: 0rem .5rem; */
    position: relative;
    margin-bottom: 0.4rem;
  }

  .custTab .layui-table {
    padding: 0;
    margin: 0;
  }

  .custTab thead {
    position: relative;
  }

  .custTab thead::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #f5d1d6;
    height: 0.56rem;
  }

  .custTab thead th {
    background: #cc1a30;
    line-height: 0.3rem;
    font-weight: 700;
    color: #fff;
    font-size: 0.26rem;
    padding: 0.15rem;
  }

  .custTab tbody {
    border-bottom: solid 3px #cc1a30;
  }

  .custTab tbody tr {
    border-bottom: solid 1px #cb1b31;
  }

  .custTab tbody td {
    color: #3e3e3e;
    font-size: 0.22rem;
    line-height: 0.3rem;
    padding: 0.15rem;
  }

  .custTab tbody tr td:last-child {
    padding-right: 0;
  }

  .custTab .btn-start {
    width: 1.28rem;
    height: 0.38rem;
    line-height: 0.38rem;
    background: #b5b5b5;
    color: #fff;
    display: inline-block;
    font-size: 0.22rem;
    text-align: center;
    transition: all 0.2s;
    float: right;
  }
  .custTab .btn-start:hover {
    background: #cc1a30;
  }

  .custTab .btn-play {
    // background: #cc1a30 url(../images/play2.png) 0.2rem center no-repeat;
    background-size: 0.26rem;
    height: 0.4rem;
    line-height: 0.4rem;
    padding-left: 0.6rem;
    width: 0.7rem;
    display: inline-block;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 0.22rem;
  }
}
</style>
